.bitcoin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* padding: 20px; */
}
.bitcoin-container h2 {
  color: #5816a7 !important ;
  margin-bottom: 10px !important;
}
.time-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 0px;
  margin-bottom: 30px;
}
.time-buttons > button {
}

.time-button {
  padding: 5px 10px !important;
  margin-right: 5px !important;
  font-size: 14px !important;
  border: none !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  background: transparent !important;
  color: #5816a7 !important;
  font-weight: 600;
  height: auto !important;
}

.time-button.selected {
  background-color: #5816a7 !important;
  color: #fff !important;
}
.time-button.selected:hover {
  background-color: #7529D0 !important;
  color: #fff !important;
}

.bitcoin-details {
  margin: 0px 0 -20px 0 !important;
  width: 100% !important;
}
.bitcoin-details > p {
  margin-bottom: 0px !important;
  font-size: 16px;
}
.line-chart {
  margin: 10px 0px;
  width: 100% !important;
}

@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 700px) {
  /* .time-buttons {
    grid-template-columns: repeat(1, 1fr);
  } */
  .time-buttons > button {
    /* transform: scale(0.9); */
  }
}
@media screen and (max-width: 480px) {
  /* .bitcoin-container {
    align-items: center;
  } */
}
