.redeem-voucher {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 30px;
  transition: all 0.5s ease-in-out !important;
}

.redeem-voucher-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.redeem-voucher h1 {
  margin-bottom: 20px;
}

.redeem-voucher p {
  width: 100%;
  /* margin: 0 auto; Center the paragraph horizontally */
  color: #5816a7;
  font-weight: 400;
  line-height: 1.6;
}

.code-input-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 16px;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
  max-width: 300px;
}

.code-input-grid input {
  width: 90%;
  height: 80px;
  text-align: center;
  font-size: 24px;
  outline: none;
  border: 2px solid #5816a7;
  border-radius: 50%;
  text-transform: uppercase;
  box-sizing: border-box;
  background-color: #f1eff2;
  color: #5816a7;
}

.code-input-grid input:focus {
  border-color: #8d6bb5;
}

.redeem-voucher button {
  width: 310px !important;
  padding-left: 30px;
  padding-right: 30px;
  height: 50px;
  font-size: 18px;
  background-color: #5816a7;
  color: #fff;
  border: none;
  border-radius: 100px;
  cursor: pointer;
}

.redeem-voucher form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.redeem-input {
  width: calc(76px * 3 + 16px * 2);
  height: 50px;
  margin-bottom: 20px;
  padding-left: 30px;
  background-color: #f1eff2;
  border: 2px solid #5816a7;
  border-radius: 100px;
  font-size: 16px;
  outline: none;
  color: #5816a7;
}

.redeem-btn-wrapper button {
  width: calc(76px * 3 + 16px * 2);
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  background-color: #5816a7;
  color: #fff;
  border: none;
  border-radius: 100px;
  cursor: pointer;
}
.redeem-btn-wrapper button:hover {
  background-color: #4b148f;
}
.voucher-details .transfer-btn {
  width: calc(76px * 3 + 16px * 2);
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  background-color: #5816a7;
  color: #fff;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  margin-left: -670px;
}

.voucher-details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}
.voucher-details h2 {
  color: #5816a7;
}
.voucher-details h2 {
  color: #5816a7;
}
.voucher-details p {
  color: #5816a7;
}
.voucher-details-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  margin-left: -525px;
}

.voucher-grid {
  display: grid;
  grid-template-columns: repeat(3, 350px);
  gap: 20px;
  margin-bottom: 40px;
  margin-top: 40px;
}

.card-designn {
  width: 100%;
  /* max-width: 300px; */
  height: 350px;
  /* height: fit-content; */
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff; /* Ensure all text is white */
  text-align: center;
  padding: 60px;
  gap: 30px;
}

.card-logo {
  width: 68px;
  height: 20px;
  /* margin-bottom: 56px; */
}

.btc-value {
  font-size: 40px;
  font-weight: 900;
  margin-bottom: -15px;
}

.btc-text {
  font-size: 30px;
  font-weight: 600;
  margin-top: -15px;
  /* margin-bottom: 40px; */
}

.personal-message {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  max-width: 80%;
}

.card-designn.design1 {
  background-color: #5816a7;
  border-radius: 50% / 10%;
}

.card-designn.design2 {
  background-color: #89c1cd;
  border-radius: 50% / 10%;
}

.card-designn.design3 {
  background-color: #c57698;
  border-radius: 50% / 10%;
}

.card-designn.design4 {
  background-color: #f3c251;
  border-radius: 50% / 10%;
}

.card-designn.design5 {
  background: linear-gradient(45deg, #a18cd1, #fbc2eb);
  border-radius: 50% / 10%;
}

.card-designn.design6 {
  background: linear-gradient(45deg, #84fab0, #8fd3f4);
  border-radius: 50% / 10%;
  /*  */
}

.card-designn.design7,
.card-designn.design8,
.design-thumbnail.design7,
.design-thumbnail.design8 {
  background-size: cover;
  background-position: center;
}

.card-designn.design7 {
  background-image: url('../assets/design7.jpg');
  border-radius: 50% / 10%;
  /*  */
}

.card-designn.design8 {
  background-image: url('../assets/design8.jpg');
  border-radius: 50% / 10%;
  /*  */
}

.current-value {
  width: 100%;
  height: 350px;
  /* height: fit-content; */
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  justify-content: center;
  text-align: center;
  gap: 30px;
  border: 2px solid #4b148f;
  border-radius: 50% / 10%;
}
.current-value h2 {
  color: #5816a7;
  font-size: 18px;
  width: 100%;

  font-weight: normal;
}
.current-value p {
  color: #5816a7;
  font-size: 40px;
  width: 100%;
  font-weight: 900;
}
.current-value p > span {
  font-weight: 600;
  color: #5816a7;
  font-size: 30px;
}
.current-value span {
  color: #5816a7;
  font-size: 14px;
  width: 100%;
  font-weight: 500;
  margin-bottom: 10px;
}
.transfer-section {
  width: 100%;
  height: 350px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  justify-content: center;
  padding: 60px;
  text-align: center;
  gap: 30px;
  border: 2px solid #4b148f;
  border-radius: 50% / 10%;
}
.transfer-section {
  color: #5816a7;
}
.transfer-section button {
  width: 260px !important;
  height: 50px;
  font-size: 18px;
  background-color: #5816a7;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3 ease-in-out;
}
.transfer-section button:hover {
  background: #4b148f;
}
.transfer-section ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  font-size: 16px;
}
.transfer-section a {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  font-size: 14px;
  color: #5816a7;
  line-height: 16px;
  margin-top: 10px;
  transition: all 0.3s ease-in-out;
  font-weight: 500;
}
.transfer-section a:hover {
  text-decoration: none;
}
.bitcoin-growth {
  width: 100%;
  height: 350px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  justify-content: center;
  padding-top: 80px;
  text-align: center;
  
  border: 2px solid #4b148f;
  border-radius: 50% / 10%;
}
.bitcoin-growth h2 {
  font-size: 18px;
  color: #5816a7;
  font-weight: 500;
  padding-bottom: 20px
}
.bitcoin-growth span {
  font-size: 30px;
  color: #5816a7;
  font-weight: 600;
}

.quote {
  width: 100%;
  height: 350px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  justify-content: center;
  padding: 60px;
  text-align: center;
  gap: 30px;
  border: 2px solid #4b148f;
  border-radius: 50% / 10%;
}
.quote p {
  color: #5816a7;
  margin-bottom: 30px;
  font-size: 14px;
  scale: 1.2;
  font-weight: 500;
  width: 100%;
}
.quote span {
  color: #5816a7;
  font-size: 16px;
}
.learn-more {
  width: 100%;
  height: 350px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* font-size: 18px; */
  justify-content: center;
  padding: 60px;
  text-align: center;
  gap: 30px;
  border: 2px solid #4b148f;
  border-radius: 50% / 10%;
  color: #5816a7;
}
.learn-more h2 {
  color: #5816a7;
  font-size: 18px;
  font-weight: normal;
  margin-top: -10px;
}
.learn-more ul {
  color: #5816a7;
  font-size: 16px;
  /* margin-top: 20px; */
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.learn-more a {
  color: #5816a7;
  font-size: 14px;
  font-weight: 500;
}
.learn-more a:hover {
  text-decoration: none;
}
@media screen and (max-width: 1140px) {
  .voucher-grid {
    grid-template-columns: repeat(2, 350px);
  }
}
@media screen and (max-width: 1024px) {
  .voucher-details-container {
    margin-left: -210px;
  }
  .voucher-details .transfer-btn {
    margin-left: -350px;
  }
}
@media screen and (max-width: 780px) {
  .voucher-grid {
    grid-template-columns: repeat(1, 350px);
    margin-top: 20px;
  }
}
@media screen and (max-width: 700px) {
  .voucher-details-container {
    margin-left: 0px;
  }
  .voucher-details .transfer-btn {
    margin-left: -150px;
  }
}
@media screen and (max-width: 600px) {
  .redeem-voucher h1 {
    width: 100%;
    font-size: 25px;
  }
  .redeem-voucher p {
    width: 100%; /* Set the width to 90% for mobile devices */
  }
  .code-grid-wrapper {
    width: 100%;
  }
  .code-input-grid input {
    height: 70px;
    max-width: 305px;
  }
  .redeem-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .redeem-btn-wrapper button {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .redeem-voucher h1 {
    width: 100%;
  }
  .redeem-voucher p {
    width: 100%; /* Set the width to 90% for mobile devices */
  }
  .code-grid-wrapper {
    width: 100%;
  }
  .code-input-grid input {
    height: 70px;
    max-width: 305px;
  }
  .redeem-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .redeem-btn-wrapper button {
    width: 100%;
  }
}
