/* VoucherSelection.css */
.voucher-selection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  padding: 0 30px;
  overflow-x: hidden;
}


.voucher-selection h1 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: -5px;
  color: #5816a7;
}

.voucher-selection p {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  color: #5816a7;
  margin-bottom: 50px;
  margin-top: 18px;
}

.desktop {
}
.receiver-bar,
.sender-bar {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  border: 2px solid #5816a7;
  border-radius: 9999px;
  margin-bottom: 20px;
  overflow: hidden;
}

.label {
  width: 90px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #5816a7;
  background-color: #e7e1eb;
  border-right: 2px solid #5816a7;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.receiver-bar .input-wrapper > input[type='text'],
.sender-bar .input-wrapper > input[type='text'],
.receiver-bar .input-wrapper > input[type='email'],
.sender-bar .input-wrapper > input[type='email'] {
  /* flex-grow: 1; */
  padding: 0 60px 0 20px;
  border: none;
  outline: none;
  color: #5816a7;
  background-color: #f1eff2;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.receiver-bar .input-wrapper > input[type='email'],
.sender-bar .input-wrapper > input[type='email'] {
  /* flex-grow: 1; */
  border-left: 2px solid #5816a7;
  height: 50px;
}

::placeholder {
  color: #5816a7;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #5816a7;
}

::-ms-input-placeholder {
  color: #5816a7;
}

.design-button {
  /* width: 200px;*/
  width: 250px;
  padding: 15px 0px;
  font-size: 14px;
  font-weight: 600;
  background-color: #5816a7;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 30px;
  margin-left: -385px;
  /* align-self: center; */
}
.mobile {
  display: none;
}

@media screen and (max-width: 800px) {
  .voucher-selection {
    width: 100%;
  }
  .desktop {
    display: none;
  }
  .voucher-selection h1 {
    margin-bottom: 18px;
  }
  .voucher-selection p {
    margin-bottom: 40px;
    margin-top: 0;
  }

  .mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 25px;
    width: 100%;
    padding: 0px;
  }
  .label-m {
    width: 90px;
    text-align: center;
    font-weight: 700;
    font-size: 15px;
    color: #5816a7;
    background-color: #e7e1eb;
    border: 2px solid #5816a7;
    height: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    margin-top: -52px;
  }
  .receiver-bar-m,
  .sender-bar-m {
    /* width: 400px; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  .input-wrapper-m {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: -2px;
  }

  .receiver-bar-m .input-wrapper-m > input[type='text'],
  .sender-bar-m .input-wrapper-m > input[type='text'] {
    padding: 0 60px 0 20px;
    border: 2px solid #5816a7;
    outline: none;
    color: #5816a7;
    background-color: #f1eff2;
    height: 51px;
    border-bottom: 1px solid #5816a7;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  .receiver-bar-m .input-wrapper-m > input[type='text']::-moz-focus-inner,
  .sender-bar-m .input-wrapper-m > input[type='text']::-moz-focus-inner {
    padding: 0;
    border: none;
  }
  .receiver-bar-m .input-wrapper-m > input[type='email'],
  .sender-bar-m .input-wrapper-m > input[type='email'] {
    padding: 0 60px 0 20px;
    border: 2px solid #5816a7;
    outline: none;
    color: #5816a7;
    background-color: #f1eff2;
    height: 52.5px;
    border-top: 1px solid #5816a7;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  .receiver-bar-m .input-wrapper-m > input[type='email']::-moz-focus-inner,
  .sender-bar-m .input-wrapper-m > input[type='email']::-moz-focus-inner {
    padding: 0;
    border: none;
  }
  .design-button {
    width: 80%;
    margin-top: 30px;
    margin-left: 0px;
  }
}

@media screen and (max-width: 500px) {
  .mobile {
    margin-left: 0px;
  }
  .design-button {
    margin-left: 0px;
  }
  .design-button {
    width: 100%;
  }
}
@media screen and (max-width: 380px) {
  .label-m {
    width: 60px;
    font-size: 13px;
  }
  .receiver-bar-m,
  .sender-bar-m {
    width: auto;
  }
  .receiver-bar-m .input-wrapper-m > input[type='text'],
  .sender-bar-m .input-wrapper-m > input[type='text'] {
    padding: 0 40px 0 20px;
  }
  .receiver-bar-m .input-wrapper-m > input[type='email'],
  .sender-bar-m .input-wrapper-m > input[type='email'] {
    padding: 0 40px 0 20px;
  }
}

/* Prevent zooming on input fields */
input[type='text'],
input[type='email'] {
  font-size: 16px; /* Ensure font size is at least 16px to prevent zooming */
}

/* Prevent yellow background on autofill */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #f1eff2 inset !important; /* Match the background color */
  -webkit-text-fill-color: #5816a7 !important; /* Match the text color */
}
