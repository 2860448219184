.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  left: 0;
  padding: 0 30px;
  width: 100%;
  background: transparent;
  text-align: center;
  font-size: 12px;
  color: #5816a7;
  border-top: 2px solid #5816a7;
  /* margin-top: 70px; */
  font-weight: 300;
  /* position: fixed; */
  height: 80px;
}

.small {
  font-size: 12px;
  margin-top: -20px;
}
.f-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 20px;
  margin-top: -20px;
}

@media screen and (max-width: 900px) {
  .footer {
  }
}
@media screen and (max-width: 600px) {
  .footer {
    justify-content: start;
    align-items: flex-start;
    flex-direction: column;
    height: auto;
  }
  .small {
    font-size: 14px;
    margin-top: 10px;
  }
  .f-wrapper {
    margin-top: 10px;
    font-size: 14px;
    cursor: pointer;
  }
  .f-wrapper a {
    text-decoration: none;
    color: #5816a7;
  }
}
