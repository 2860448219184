/* Checkout.css */
.checkout {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 30px;
  width: 100%;
}

.checkout h1 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 10px;
}

.checkout p {
  width: 500px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  color: #5816a7;
  margin-bottom: 20px;
}

.checkout-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  width: 70%;
  padding: 10px;
  gap: 50px;
}

.checkout-preview {
  display: flex;
  justify-content: center;
  margin-bottom: -200px;
}

.checkout-design {
  max-width: 350px;
  height: fit-content;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff; /* Ensure all text is white */
  text-align: center;
  padding: 60px;
  gap: 30px;
}

.checkout-logo {
  width: 68px;
  height: 20px;
  /* margin-bottom: 56px; */
}

.btc-value {
  font-size: 45px;
  font-weight: 900;
  margin-bottom: -15px;
}

.btc-text {
  font-size: 36px;
  font-weight: 600;
  margin-top: -15px;
}

.personal-message {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  max-width: 80%;
}

.checkout-design.design1 {
  background-color: #5816a7;
  border-radius: 50% / 10%;
}

.checkout-design.design2 {
  background-color: #89c1cd;
  border-radius: 50% / 10%;
}

.checkout-design.design3 {
  background-color: #c57698;
  border-radius: 50% / 10%;
}

.checkout-design.design4 {
  background-color: #f3c251;
  border-radius: 50% / 10%;
}

.checkout-design.design5 {
  background: linear-gradient(45deg, #a18cd1, #fbc2eb);
  border-radius: 50% / 10%;
}

.checkout-design.design6 {
  background: linear-gradient(45deg, #84fab0, #8fd3f4);
  border-radius: 50% / 10%;
}

.checkout-design.design7,
.checkout-design.design8,
.design-thumbnail.design7,
.design-thumbnail.design8 {
  background-size: cover;
  background-position: center;
}

.checkout-design.design7 {
  background-image: url('../assets/design7.jpg');
  border-radius: 50% / 10%;
}

.checkout-design.design8 {
  background-image: url('../assets/design8.jpg');
  border-radius: 50% / 10%;
}
.buynow-button {
  background-color: #5816a7;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 25px;
  padding: 12px 40px;
}

/* Media Queries */
@media (max-width: 1024px) {
  .checkout-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    align-items: center;
    width: 100%;
    /* padding: 10px; */
    gap: 40px;
  }

  .checkout-preview {
    justify-content: start;
    margin-bottom: 0px;
  }
  .checkout-wrapper p {
    width: 100%;
  }
  .checkout-wrapper {
    padding: 0px;
  }
}

@media screen and (max-width: 820px) {
  .buynow-button {
    margin-top: 20px;
  }
}
@media screen and (max-width: 700px) {
  .checkout-preview {
    justify-content: start;
    margin-bottom: 0px;
    margin-top: -40px;
  }
  .checkout-wrapper {
    padding: 0px;
  }
}

@media screen and (max-width: 430px) {
  .checkout-design {
    width: 100%;
  }

  @media screen and (max-width: 360px) {
    .checkout-wrapper {
      transform: scaleX(0.9);
    }
  }
  @media screen and (max-width: 340px) {
    .checkout-wrapper {
      transform: scaleX(0.8);
    }
  }
}
