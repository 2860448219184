/*PurchaseSuccess.css*/
.purchase-successs-conatiner {
  max-width: 1440px;
}
.purchase-successs-wrapper {
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1440px;
}

.purchase-success {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.purchase-success h1 {
  font-size: 26px;
  margin-bottom: 18px;
}
.purchase-success p {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  color: #5816a7;
  margin-bottom: 20px;
  color: #5816a7;
}
.purchase-success img {
  max-width: 1440px;
  height: 500px;
  object-fit: contain;
  margin-bottom: 20px;
}

.purchase-success button {
  border-radius: 9999px;
  width: 250px;
  margin-bottom: 40px;
}

@media screen and (max-width: 950px) {
  .purchase-success img {
    height: 50vh;
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .purchase-success button {
    width: 100%;
  }
  .purchase-success img {
    height: auto;
  }
}
