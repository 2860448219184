/*redeemSuccess.css*/
.redeem-successs-conatiner {
  /* width: 100%; */
  max-width: 1440px;
}
.redeem-successs-wrapper {
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1440px;
}

.redeem-success {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.redeem-success h1 {
  font-size: 26px;
  margin-bottom: 18px;
}
.redeem-success p {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  color: #5816a7;
  margin-bottom: 20px;
  color: #5816a7;
}
.redeem-success .imgWrapper {
  max-width: 1440px;
  object-fit: contain;
}
.redeem-success img {
  max-width: 1440px;
  height: 500px;
  /* object-fit: cover; */
  margin-bottom: 40px;
}

.redeem-success button {
  border-radius: 9999px;
  width: 250px;
  margin-bottom: 40px;
}

@media screen and (max-width: 950px) {
  .redeem-success img {
    height: 50vh;
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .redeem-success button {
    width: 100%;
  }
  .redeem-success img {
    height: auto;
  }
}
