/* App.css */
body {
  background-color: #f1eff2;
  font-family: 'Fabio XM', sans-serif;
}

h1 {
  font-family: 'Fabio XM', sans-serif;
  color: #5816a6;
}
/* .app img {
  font-family: 'Fabio XM', sans-serif;
  color: #5816a6;
} */
.app .logo {
  font-family: 'Fabio XM', sans-serif;
  color: #5816a6;
  padding: 30px;
  font-size: 40px;
  font-weight: 700;
}
.app {
  min-height: 100vh;
}
.app > img {
  font-family: 'Fabio XM', sans-serif;
  color: #5816a6;

  /* padding-left: 20px; */
}

p {
  font-family: 'Fabio XM', sans-serif;
  font-weight: 500;
}

button {
  background-color: #5816a6;
  color: #fff;
  border: none;
  padding: 16px 30px;
  cursor: pointer;
}
.app {
  margin-bottom: 20px;
}
