/* VoucherPurchase.css */
.voucher-purchase {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

@media screen and (max-width: 770px) {
  .step {
    justify-content: flex-start;
  }
}
