/* CardDesign.css */
.card-container {
  /* margin: 0 auto; */
  padding: 0 30px;
  overflow: hidden;
  /* min-height: 100vh; */
}
.card-design-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  width: 100%;
}

.column-layout {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  width: 70%;
  /* padding: 10px; */
  /* gap: 50px; */
}

.left-column-wrapper,
.right-column-wrapper,
.last-column-wrapper {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
}

.left-column {
}

.right-column {
}

.left-column-wrapper h1 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 10px;
}

.card-design-container p {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  color: #5816a7;
  margin-bottom: 20px;
  width: 450px;
}

.amount-options {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto auto;
  margin-bottom: 20px;
  width: 5px;
  margin-left: -10px;
}

.amount-option {
  width: 66px;
  height: 66px;
  background-color: #f1eff2;
  border: 2px solid #5816a7;
  border-radius: 50%;
  margin: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5816a7;
  font-size: 14px;
  font-weight: 600;
}

.amount-option.selected {
  background-color: #5816a7;
  color: #fff;
}

.card-preview {
  display: flex;
  justify-content: center;

  /* margin-bottom: 20px; */
}

.card-design {
  width: 100%;
  max-width: 350px;
  height: 350px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff; /* Ensure all text is white */
  text-align: center;
  padding: 60px;
  gap: 30px;
}

.card-logo {
  width: 68px;
  height: 20px;
}

.btc-value {
  font-size: 45px;
  font-weight: 900;
  margin-bottom: -15px;
}

.btc-text {
  font-size: 36px;
  font-weight: 600;
  margin-top: -15px;
}

.personal-message {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  max-width: 80%;
}

.card-design.design1 {
  background-color: #5816a7;
  border-radius: 50% / 10%;
}

.card-design.design2 {
  background-color: #89c1cd;
  border-radius: 50% / 10%;
}

.card-design.design3 {
  background-color: #c57698;
  border-radius: 50% / 10%;
}

.card-design.design4 {
  background-color: #f3c251;
  border-radius: 50% / 10%;
}

.card-design.design5 {
  background: linear-gradient(45deg, #a18cd1, #fbc2eb);
  border-radius: 50% / 10%;
}

.card-design.design6 {
  background: linear-gradient(45deg, #84fab0, #8fd3f4);
  border-radius: 50% / 10%;
  /*  */
}

.card-design.design7,
.card-design.design8,
.design-thumbnail.design7,
.design-thumbnail.design8 {
  background-size: cover;
  background-position: center;
}

.card-design.design7 {
  background-image: url('../assets/design7.jpg');
  border-radius: 50% / 10%;
  /*  */
}

.card-design.design8 {
  background-image: url('../assets/design8.jpg');
  border-radius: 50% / 10%;
  /*  */
}

.design-thumbnail.design7 {
  background-image: url('../assets/thumb_design7.jpg');
}

.design-thumbnail.design8 {
  background-image: url('../assets/thumb_design8.jpg');
}

.design-thumbnails {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.design-thumbnail {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  margin: 5px;
  cursor: pointer;
}

.design-thumbnail.selected {
  border: 2px solid #5816a7;
  border-color: #5816a7;
  box-shadow: 0 0 0 6px #f1eff2 inset;
}

.design-thumbnail.design1 {
  background-color: #5816a7;
}

.design-thumbnail.design2 {
  background-color: #89c1cd;
}

.design-thumbnail.design3 {
  background-color: #c57698;
}

.design-thumbnail.design4 {
  background-color: #f3c251;
}

.design-thumbnail.design5 {
  background: linear-gradient(45deg, #a18cd1, #fbc2eb);
}

.design-thumbnail.design6 {
  background: linear-gradient(45deg, #84fab0, #8fd3f4);
}
.last-column {
  width: 100%;
  /* padding: 10px; */
  /* margin-left: -650px; */
}

textarea {
  font-family: 'Fabio XM', sans-serif;
  color: #5816a7;
  font-size: 16px;
  width: 326px;
  height: 200px;
  background-color: #f1eff2;
  padding: 20px;
  border: 2px solid #5816a7;
  border-radius: 10px;
  resize: none;
  margin-bottom: 20px;
}

textarea:focus {
  outline: none;
}

.error-message {
  color: red;
  margin-bottom: 20px;
}

.checkout-button {
  background-color: #5816a7;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 25px;
  padding: 12px 40px;
}
.back-button {
  background-color: #5816a7;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 25px;
  padding: 12px 40px;
  margin-right: 10px;
}

@media screen and (max-width: 1024px) {
  .column-layout {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 50px;
  }
  .card-preview {
    justify-content: start;
  }
  .card-design-container {
    padding: 0;
    /* margin-left: 100px; */
  }
  .card-design-container p {
    width: 370px;
  }
  .design-thumbnails {
    margin-bottom: 40px;
    margin-top: -10px;
  }
}
@media screen and (max-width: 800px) {
  .card-design-container p {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .left-column {
    margin-bottom: 0;
  }

  .amount-options {
    grid-template-columns: repeat(2);
  }
}
@media screen and (max-width: 768px) {
  .right-column {
    margin-top: -50px;
  }
}

@media screen and (max-width: 530px) {
  .card-design {
    max-width: 100%;
  }
  textarea {
    width: 100%;
  }
}
@media screen and (max-width: 390px) {
  .card-design-container {
    transform: scaleX(0.95);
  }
}
@media screen and (max-width: 375px) {
  .card-design-container {
    transform: scaleX(0.9);
  }
}
@media screen and (max-width: 360px) {
  .card-design-container {
    transform: scaleX(0.83);
  }
}
@media screen and (max-width: 320px) {
  .card-design-container {
    transform: scaleX(0.73);
  }
}
