.wallet-selection-page {
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.wallet-selection-page > h1 {
  display: flex;
  flex-wrap: wrap;
}
.wallet-selection-page > p {
  display: flex;
  flex-wrap: wrap;
  color: #5816a7;
  font-weight: 500;
  width: 500px;
}
form {
  width: 500px;
}

form > .radios {
  margin: 10px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  color: #5816a7;
}

form > .radio-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 10px 0;
}

form > .radios .radio-2 {
  margin: 10px 0;
}
form > .radios .radio-1 > input {
  margin: 0 10px 0 10px;
  scale: 1.3;
  color: #5816a7;
}
form > .radios .radio-2 > input {
  margin: 0 10px;
  scale: 1.3;
  color: #5816a7;
}
form .inputs-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}
form .email-input {
  border: 2px solid #5816a7;
  border-radius: 50px;
}
form .wallet-address-input {
  border: 2px solid #5816a7;
  border-radius: 50px;
}
form .email-input input {
  height: 50px;
  background: transparent;
  width: 400px;
  border: 0;
  outline: 0;
  padding: 15px;
  border-left: 2px solid #5816a7;
  color: #5816a7;
}
form .wallet-address-input input {
  height: 50px;
  background: transparent;
  width: 335px;
  border: 0;
  outline: 0;
  padding: 15px;
  border-left: 2px solid #5816a7;
  color: #5816a7;
}
form .email-input > label {
  padding: 14px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  height: 50px;
  background: #e7e1eb;
  color: #5816a7;
  font-weight: 600;
  font-size: 15px;
}
form .wallet-address-input > label {
  padding: 14px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  color: #5816a7;
  font-weight: 600;
  height: 50px;
  background: #e7e1eb;
  font-size: 15px;
}
form button {
  width: 200px;
  margin-top: 40px;
  border-radius: 50px;
  background: #5816a7;
  color: #e7e1eb;
}
form button:hover {
  background: #4b148f;
}

@media screen and (max-width: 600px) {
  .wallet-selection-page > h1 {
    font-size: 25px;
  }
  .wallet-selection-page > p {
    width: 400px;
  }
  form .btn-wrraper {
    width: 100%;
    padding: 16px 65px;
  }
  form button {
    width: 100%;
  }
}
@media screen and (max-width: 550px) {
  form .inputs-container {
    align-items: center;
    padding: 0 65px;
  }
  form .radios {
    justify-content: start;
    padding: 0 57px;
  }
  .wallet-selection-page > h1 {
    font-size: 25px;
  }
  .wallet-selection-page > p {
    /* width: 300px; */
  }
  form .email-input {
    /* width: 300px; */
    width: 100%;
  }
  form .wallet-address-input {
    width: 100%;
  }
}
@media screen and (max-width: 430px) {
  .wallet-selection-page > h1 {
    font-size: 25px;
  }
  .wallet-selection-page > p {
    padding: 0;
    width: 100%;
  }
  form .email-input input {
    width: 250px;
  }
  form .wallet-address-input input {
    width: 180px;
  }

  form > .radios {
    margin: 20px 0;
  }
}
